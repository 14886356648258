import React from 'react';

import Section from '../../components/UI/Section/Section';
import SectionHeading from '../../components/UI/SectionHeading/SectionHeading';

import CompanyValues from '../../components/CompanyValues/CompanyValues';
import PersonCard from '../../components/PersonCard/PersonCard';
import companyExpertiseMapping from '../../assets/json/companyExpertise.json';
import companyValuesMapping from '../../assets/json/companyValues.json';

const About = () => (
    <React.Fragment>
        <Section>
            <div className={`u-textAlignCenter mb-20 phone-mb-10`}>
                <SectionHeading>About The Firm</SectionHeading>
            </div>
            <div className="u-width80Percent u-tabPort-width100Percent u-textAlignCenter u-phone-textAlignLeft mx-auto">
                <p className="u-fontSize16 u-fontWeightRegular u-textColorDark mb-4">With over 35 years of history, Sandeep Kumar and Associates has been one of the prominent Chartered accountancy firms in Delhi and NCR providing wide array financial and advisory services to numerous MNCs as well as reputed Indian companies.</p>
                <p className="u-fontSize16 u-fontWeightRegular u-textColorDark mb-4">In 1984, CA. Sandeep Kumar (Founder) laid down a solid foundation for the brand with strong emphasis on client satisfaction and delivering excellence in Accounting, Auditing, Taxation, Assurance and Business Advisory services.</p>
                <p className="u-fontSize16 u-fontWeightRegular u-textColorDark mb-4">Following an employee and client friendly culture, we believe in delivering accurate, relevant and timely information to the decision makers using industry expertise. We help our clients to stay compliant and lawful. We aim at applying intelligence and expertise by providing realistic solutions to complicated business scenarios using analytical skills, functional expertise and intensive training. Our result oriented and success driven team believes in providing equal services to clients of all sizes and at all locations.</p>
            </div>
        </Section>
        <Section>
            <div className={`u-textAlignCenter mb-20 phone-mb-10`}>
                <SectionHeading>Our Expertise</SectionHeading>
            </div>
            <div>
                <ul className="u-flex f-wrap justify-content-between">
                    {companyExpertiseMapping.map(value => <CompanyValues 
                        key={value.id.toString()} 
                        index={value.id} 
                        title={value.title} 
                        description={value.description}
                        isLastRowItem={value.id > companyExpertiseMapping.length - 1} />)}
                </ul>
            </div>
        </Section>
        <Section>
            <div className={`u-textAlignCenter mb-20 phone-mb-10`}>
                <SectionHeading>Our Team</SectionHeading>
            </div>
            <div className={`u-flex f-row phone-f-column`}>
                <PersonCard name='CA. Sandeep Kumar' position='Founding Partner' description='An epitome of humbleness and professionalism, he is a widely respected Income Tax Expert who represent his clients at I.T. Appeals and ITAT. Known for his jovial and straight-forward attitude, he believes in nurturing his client’s business as his own.' image='sandeep-kumar'/>
                <PersonCard name='CA. Ayush Kumar' position='Partner' description='An influential person at work, he is known for his calm and composed behavior during tough situations. His key areas of work include direct and indirect taxation with special emphasis on implementation of GST for different types of businesses. He maintains high standards of professionalism amidst his peers and leads by example.' image='ayush-kumar' />
            </div>
        </Section>
        <Section>
            <div className={`u-textAlignCenter mb-20 phone-mb-10`}>
                <SectionHeading>Our Values</SectionHeading>
            </div>
            <div>
                <ul className="u-flex f-wrap justify-content-between">
                    {companyValuesMapping.map(value => <CompanyValues 
                        key={value.id.toString()} 
                        index={value.id} 
                        title={value.title} 
                        description={value.description}
                        isLastRowItem={value.id > companyValuesMapping.length - 1} />)}
                </ul>
            </div>
        </Section>
        {/* <Section>
            <div className={`u-textAlignCenter mb-10`}>
                <SectionHeading>What Others Say About Us</SectionHeading>
            </div>
            <div className={`u-flex f-row phone-f-column`}>
                <div className={`f-1 pr-5 phone-pr-0 phone-pb-5`}>
                    <ul>
                        <li className={`pb-5`}>
                            <Testimonial/>
                        </li>
                        <li className={`pt-5 phone-pt-0`}>
                            <Testimonial/>
                        </li>
                    </ul>
                </div>
                <div className={`f-1 pl-5 phone-pl-0`}>
                    <ul>
                        <li className={`pb-5 mt-6 phone-mt-0`}>
                            <Testimonial/>
                        </li>
                        <li className={`pt-5 phone-pt-0 mt-6 phone-mt-0`}>
                            <Testimonial/>
                        </li>
                    </ul>
                </div>
            </div>
        </Section> */}
    </React.Fragment>
);

export default About;