import React from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Dropdown = (props) => (
    <React.Fragment>
    <div className="u-height15 u-width100Percent u-opacity0 u-backgroundColorWhite u-phoneHide"></div>
    <div className={`u-borderRadius4 u-backgroundColorWhite u-boxShadowDropdown u-absolute u-right10 u-zIndex500 u-transition-all300Custom service-dropdown`} style={{
        opacity: props.isActive || (!props.isCollapsed && window.innerWidth <= 600) ? 1 : 0,
        visibility: props.isActive || (!props.isCollapsed && window.innerWidth <= 600) ? 'visible' : 'hidden'
    }}>
        
        <div className={`dropdown-arrow u-phoneHide`}></div>
        <div className={`pt-4 phone-pt-0 pb-4`}>
            <ul className="phone-pl-4vw">
                <li className="u-backgroundColorGrayHover pl-6 pr-6 phone-pl-0 phone-pr-0">
                    <Link className={`u-flex align-items-center pt-2 pb-2`} to='/service/income-tax'>
                        <div className={`u-size48x48 u-borderRadius50 u-backgroundColorOne u-flex justify-content-center align-items-center`} style={{
                            flex: '0 0 4.8rem'
                        }}>
                            <FontAwesomeIcon icon='file-invoice' className={`u-textColorOne`} size="lg"/>
                        </div>
                        <div className={`ml-4 u-flex f-column f-fill align-items-start justify-content-center`}>
                            <div className={`mb-1`}>
                                <h3 className={`u-fontSize16 u-textTransformUppercase u-fontWeightMedium u-lineHeight22 m-0 u-textColorOne`}>Income Tax</h3>
                            </div>
                            <p className={`u-fontSize14 u-textColorNormal u-lineHeight22 u-phoneHide`}>Easy and simple income tax solutions.</p>
                        </div>
                    </Link>
                </li>
                <li className="u-backgroundColorGrayHover pl-6 pr-6 phone-pl-0 phone-pr-0">
                    <Link className={`u-flex align-items-center pt-2 pb-2`} to='/service/company-law'>
                        <div className={`u-size48x48 u-borderRadius50 u-backgroundColorTwo u-flex justify-content-center align-items-center`} style={{
                            flex: '0 0 4.8rem'
                        }}>
                            <FontAwesomeIcon icon='gavel' className={`u-textColorTwo`} size="lg"/>
                        </div>
                        <div className={`ml-4 u-flex f-column f-fill align-items-start justify-content-center`}>
                            <div>
                                <h3 className={`u-fontSize16 u-textTransformUppercase u-fontWeightMedium u-lineHeight22 m-0 u-textColorTwo`}>Company Law</h3>
                            </div>
                            <p className={`u-fontSize14 u-textColorNormal u-lineHeight22 u-phoneHide`}>Hassle free and correct compliance of Corporate laws.</p>
                        </div>
                    </Link>
                </li>
                <li className="u-backgroundColorGrayHover pl-6 pr-6 phone-pl-0 phone-pr-0">
                    <Link className={`u-flex align-items-center pt-2 pb-2`} to='/service/business'>
                        <div className={`u-size48x48 u-borderRadius50 u-backgroundColorThree u-flex justify-content-center align-items-center`} style={{
                            flex: '0 0 4.8rem'
                        }}>
                            <FontAwesomeIcon icon='briefcase' className={`u-textColorThree`} size="lg"/>
                        </div>
                        <div className={`ml-4 u-flex f-column f-fill align-items-start justify-content-center`}>
                            <div>
                                <h3 className={`u-fontSize16 u-textTransformUppercase u-fontWeightMedium u-lineHeight22 m-0 u-textColorThree`}>Business Services</h3>
                            </div>
                            <p className={`u-fontSize14 u-textColorNormal u-lineHeight22 u-phoneHide`}>Build and expand your unique business ideas.</p>
                        </div>
                    </Link>
                </li>
                <li className="u-backgroundColorGrayHover pl-6 pr-6 phone-pl-0 phone-pr-0">
                    <Link className={`u-flex align-items-center pt-2 pb-2`} to='/service/gst'>
                        <div className={`u-size48x48 u-borderRadius50 u-backgroundColorFour u-flex justify-content-center align-items-center`} style={{
                            flex: '0 0 4.8rem'
                        }}>
                            <FontAwesomeIcon icon='coins' className={`u-textColorFour`} size="lg"/>
                        </div>
                        <div className={`ml-4 u-flex f-column f-fill align-items-start justify-content-center`}>
                            <div>
                                <h3 className={`u-fontSize16 u-textTransformUppercase u-fontWeightMedium u-lineHeight22 m-0 u-textColorFour`}>GST</h3>
                            </div>
                            <p className={`u-fontSize14 u-textColorNormal u-lineHeight22 u-phoneHide`}>GST integration and compliance services for your enterprise.</p>
                        </div>
                    </Link>
                </li>
                <li className="u-backgroundColorGrayHover pl-6 pr-6 phone-pl-0 phone-pr-0">
                    <Link className={`u-flex align-items-center pt-2 pb-2`} to='/service/audit-attestation'>
                        <div className={`u-size48x48 u-borderRadius50 u-backgroundColorFive u-flex justify-content-center align-items-center`} style={{
                            flex: '0 0 4.8rem'
                        }}>
                            <FontAwesomeIcon icon='search' className={`u-textColorFive`} size="lg"/>
                        </div>
                        <div className={`ml-4 u-flex f-column f-fill align-items-start justify-content-center`}>
                            <div>
                                <h3 className={`u-fontSize16 u-textTransformUppercase u-fontWeightMedium u-lineHeight22 m-0 u-textColorFive`}>Audit &amp; Attestation</h3>        
                            </div>
                            <p className={`u-fontSize14 u-textColorNormal u-lineHeight22 u-phoneHide`}>Check your business's financial health and performance.</p>
                        </div>
                    </Link>
                </li>
            </ul>
        </div>
    </div>
    </React.Fragment>
);

export default Dropdown;