import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Service from '../../components/Service/Service';
import servicesMapping from '../../assets/json/servicesMapping.json';

const Services = () => (
    <div>
        <Switch>
            {
                servicesMapping.map((service, index) => {
                    return <Route key={index} path={service.reactUrl} render={() => <Service 
                        type={service.type} 
                        title={service.title} 
                        description={service.description} 
                        heading={service.heading} 
                        icon={service.icon} 
                        colorCode={service.colorCode} 
                        backgroundColorCode={service.backgroundColorCode} 
                        />
                    }/>
                })
            }
        </Switch>
    </div>
);

export default Services;