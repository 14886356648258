import React, { Component } from 'react';
import { Element , Events, animateScroll as scroll, scroller } from 'react-scroll';

import Container from '../../components/UI/Container/Container';
import Section from '../../components/UI/Section/Section';
import SectionSkew from '../../components/UI/SectionSkew/SectionSkew';
import Card from '../../components/ServiceCard/ServiceCard';
import ButtonPrimaryLarge from '../../components/UI/ButtonPrimaryLarge/ButtonPrimaryLarge';

import homeImage from '../../assets/images/home.jpg';
import services from '../../assets/json/servicesMapping.json';

class Home extends Component {

    componentDidMount() {
        Events.scrollEvent.register('begin');
        Events.scrollEvent.register('end');
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
        <React.Fragment>
            <Container>
                <Section>
                    <div className={`u-flex f-row tabPort-f-column phone-pt-10 phone-pb-10`}>
                        <div className={`u-width50Percent u-flex f-column justify-content-center u-tabPort-width100Percent u-tabPort-textAlignCenter`}>
                            <div className={`u-width80Percent u-tabPort-width100Percent`}>
                                <h1 className={`u-fontSize42 u-phone-fontSize36 u-tabPort-fontSize38 u-textColorDarkest u-fontWeightSemiBold u-lineHeight12 mb-4`}>Stay Compliant, <br className="u-tabPortHide"/>Hassle Free</h1>
                                <h2 className={`u-fontSize18 u-textColorDark u-fontWeightRegular u-lineHeight15 mb-8`}>We provide a one-stop shop solution for every tax and finance requirement in the most cost effective and efficient manner.</h2>
                                <ButtonPrimaryLarge onClick={() => scroller.scrollTo('sectionGrid', {
                                    duration: 1500,
                                    delay: 50,
                                    smooth: true,
                                    offset: 0
                                })}>Get Started</ButtonPrimaryLarge>
                            </div>
                        </div>
                        <div className={`u-width50Percent mx-auto u-relative u-tabPort-width75Percent u-tabPort-orderNegative phone-mb-2`}>
                            <img src={homeImage} alt='Home' className={`u-width100Percent`}/>
                        </div>
                    </div>
                </Section>
            </Container>
            <Element name='sectionGrid'>
                <SectionSkew>
                    <Container>
                        <div className={`mb-4 u-textAlignCenter`}>
                            <h2 className={`u-fontSize42 u-textColorDarkest u-fontWeightSemiBold u-lineHeight12`}>Our Services</h2>
                        </div>
                        <div className={`mb-10 u-textAlignCenter`}>
                            <h3 className={`u-fontSize18 u-textColorDark u-fontWeightRegular u-lineHeight15`}>One Stop Shop For All Your Business Needs</h3>
                        </div>
                        <div className={`services-grid`}>
                            {
                                services.map((service, index) => {
                                    return <Card 
                                        data={service} 
                                        title={service.title} 
                                        description={service.description} 
                                        colorCode={service.colorCode}
                                        backgroundColorCode={service.backgroundColorCode}
                                        icon={service.icon}
                                        reactUrl={service.reactUrl}
                                        key={index} />
                                })
                            }
                        </div>
                    </Container>
                </SectionSkew>
            </Element>
            <section className={`pt-12 pb-12 u-relative u-zIndex500 u-backgroundColorDark`}>
                <Container>
                    <div className={`mb-4 u-textAlignCenter u-textColorWhite`}>
                        <h2 className={`u-fontSize28 u-fontWeightSemiBold u-lineHeight12`}>Ready to make your life easier?</h2>
                    </div>
                    <div className={`mb-6 u-textAlignCenter u-textColorWhite`}>
                        <h3 className={`u-fontSize18 u-fontWeightMedium u-lineHeight12`}>Taking care of our customers since 1984</h3>
                    </div>
                    <div className={`u-textAlignCenter`}>
                        <ButtonPrimaryLarge onClick={() => scroller.scrollTo('sectionGrid', {
                            duration: 1500,
                            delay: 50,
                            smooth: true,
                            offset: 0
                        })}>Get Started</ButtonPrimaryLarge>
                    </div>
                </Container>
            </section>
        </React.Fragment>
        );
    }
}
export default Home;