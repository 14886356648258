import React from 'react';

const CompanyValues = (props) => {
    const { index, title, description, isLastRowItem } = props;
    return (
        <li className={`${isLastRowItem ? 'mb-0' : 'mb-20 phone-mb-10'} pr-4 u-width46Percent u-phone-width100Percent phone-pr-0`}>
            <span className="u-fontSize18 u-textColorPrimary u-fontWeightSemiBold u-lineHeight1 u-relative u-backgroundEffect">{index < 10 ? '0' + index : index}</span>
            <h3 className="mb-4 u-fontSize18 u-textColorDarker u-fontWeightSemiBold u-lineHeight1">{title}</h3>
            <p className="u-fontSize16 u-fontWeightRegular u-textColorDark">{description}</p>
        </li>
    );
}

export default CompanyValues;