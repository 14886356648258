import React from 'react';

import Section from '../../components/UI/Section/Section';
import SectionHeading from '../../components/UI/SectionHeading/SectionHeading';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Careers = () => (
    <Section>
        <div className={`mb-8 u-textAlignCenter`}>
            <SectionHeading>We're hiring.</SectionHeading>
        </div>
        <div className={`mb-10 u-textAlignCenter`}>
            <div className={`u-maxWidth760 mx-auto`}>
                <p className={`u-textColorDarker u-fontWeightRegular u-fontSize16`}>At Sandeep Kumar &amp; Associates, we help young and bright professionals sharpen their skills in nascent stages of their career. We focus on imparting high levels of ethical standards and confidence to deal with any kind of adverse situation.</p>
            </div>
        </div>
        <div className={`u-maxWidth760 mx-auto`}>
            <ul>
                <li className={`mb-5 u-backgroundColorWhite u-backgroundColorPrimaryLightHover u-transition--transform300Spring`}>
                    <Link className={`u-flex align-items-center justify-content-between u-textAlignLeft u-borderNormal p-5 u-borderRadius12`} to='/job/article-assistant'>
                        <div className={`u-flex align-items-center`} style={{flex: 10}}>
                            <h4 className={`u-fontSize20 u-phone-fontSize18 u-fontWeightSemiBold u-textColorDarker`} style={{flex: 7}}>Article Assistant</h4>
                            <p className={`u-textColorDark u-fontSize18 u-phone-fontSize16`} style={{flex: 3}}>Gurgaon</p>
                        </div>
                        <span><FontAwesomeIcon icon="angle-right" className={`u-textColorPrimary`}/></span>
                    </Link>
                </li>
                <li className={`u-backgroundColorWhite u-backgroundColorPrimaryLightHover u-transition--transform300Spring`}>
                    <Link className={`u-flex align-items-center justify-content-between u-textAlignLeft u-borderNormal p-5 u-borderRadius12`} to='/job/internship-for-graduation-students'>
                        <div className={`u-flex align-items-center`} style={{flex: 10}}>
                            <h4 className={`u-fontSize20 u-phone-fontSize18 u-fontWeightSemiBold u-textColorDarker`} style={{flex: 7}}>Internship&nbsp;<span className="u-fontSize16 u-fontWeightMedium u-textColorNormal">(Graduation students)</span></h4>
                            <p className={`u-textColorDark u-fontSize18 u-phone-fontSize16`} style={{flex: 3}}>Gurgaon</p>
                        </div>
                        <span><FontAwesomeIcon icon="angle-right" className={`u-textColorPrimary`}/></span>
                    </Link>
                </li>
            </ul>
        </div>
    </Section>
);

export default Careers;