import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from "react-router-dom";
import Dropdown from '../../components/Dropdown/Dropdown';
import Hamburger from '../../components/Hamburger/Hamburger';

class Header extends Component {

    state = { 
        isDropdownActive: false,
        isMobileMenuCollapsed: true
    }

    onHoverInHandler = () => this.setState({ isDropdownActive: true });
    onHoverOutHandler = () => this.setState({ isDropdownActive: false });

    toggleMobileMenuHandler = () => this.setState(prevState => ({
        isMobileMenuCollapsed: !prevState.isMobileMenuCollapsed
    }))

    componentWillMount() {
        this.unlisten = this.props.history.listen(() => this.setState({ 
            isDropdownActive: false,
            isMobileMenuCollapsed: true 
        }));      
    }

    componentWillUnmount() {
        this.unlisten(); 
    }

    render() {
        let hamburgerBg = null;
        let pathName = this.props.location.pathname;
        if (pathName === '/service/income-tax') {
            hamburgerBg = 'one';
        } else if (pathName === '/service/company-law') {
            hamburgerBg = 'two';
        } else if (pathName === '/service/business') {
            hamburgerBg = 'three';
        } else if (pathName === '/service/gst') {
            hamburgerBg = 'four';
        } else if (pathName === '/service/audit-attestation') {
            hamburgerBg = 'five';
        }

        return (
            <header className='u-flex align-items-center u-width100Percent u-height80 u-phone-heightAuto u-backgroundColorWhite u-zIndex900 u-boxShadowHeader u-fixed'>
                <nav className='u-flex justify-content-between align-items-center u-width100Percent u-height100Percent u-maxWidth1140 pl-3 pr-3 mx-auto tabLand-pl-4 tabLand-pr-4 phone-pl-3 phone-pr-3 phone-f-row'>
                    <div className="phone-mb-4 phone-mt-4">
                        <NavLink to="/" className={`u-flex f-column align-items-center phone-align-items-start`}>
                            <span className={`u-fontSize22 u-phone-fontSize20 u-textColorDarker u-fontWeightSemiBold u-lineHeight1`}>Sandeep Kumar &amp; Associates</span>
                            <span className={`u-fontSize14 u-textColorLight u-fontWeightMedium`}>Chartered Accountants</span>
                        </NavLink>
                    </div>
                    <div className="u-phoneShow">
                        <Hamburger 
                            bgColor={hamburgerBg}
                            clicked={this.toggleMobileMenuHandler} 
                            isCollapsed={this.state.isMobileMenuCollapsed}/>
                    </div>
                    {
                        !this.state.isMobileMenuCollapsed ? <div className="u-absolute u-block u-right0 u-top80 u-phoneShow u-overflowScroll u-backgroundColorWhite u-width100Percent u-height100PercentOffset80 u-borderTopFooter">
                            <ul className={`u-flex f-column phone-pl-4vw phone-pr-4vw`}>
                                <li onMouseEnter={this.onHoverInHandler} onMouseLeave={this.onHoverOutHandler} className="u-block u-phone-borderBottomNormal">
                                    <span className={`u-fontSize22 u-textColorNormal u-textColorDarkerHover pt-4 pb-4 u-block u-transition--transform300Spring u-cursorPointer`}>Services</span>
                                    <Dropdown isActive={this.state.isDropdownActive} isCollapsed={this.state.isMobileMenuCollapsed}/>
                                </li>
                                <li className="u-block u-phone-borderBottomNormal">
                                    <NavLink 
                                        to="/about" 
                                        activeClassName={`u-textColorDarker`} 
                                        className={`u-fontSize22 u-textColorNormal u-textColorDarkerHover pt-4 pb-4 u-block u-transition--transform300Spring`}>
                                            About
                                    </NavLink>
                                </li>
                                <li className="u-block u-phone-borderBottomNormal">
                                    <NavLink to="/contact" activeClassName={`u-textColorDarker`} className={`u-fontSize22 u-textColorNormal u-textColorDarkerHover pt-4 pb-4 u-block u-transition--transform300Spring`}>Contact</NavLink>
                                </li>
                                <li className="u-block">
                                    <NavLink to="/careers" activeClassName={`u-textColorDarker`} className={`u-fontSize22 u-textColorNormal u-textColorDarkerHover pt-4 pb-4 u-block u-transition--transform300Spring`}>Career</NavLink>
                                </li>
                            </ul>
                        </div> : null
                    }
                    <div className="u-phoneHide mt-3">
                        <ul className={`u-flex`}>
                            <li onMouseEnter={this.onHoverInHandler} onMouseLeave={this.onHoverOutHandler} className="u-relative">
                                <span className={`u-fontSize16 u-phone-fontSize20 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring u-cursorPointer`}>Services</span>
                                <Dropdown isActive={this.state.isDropdownActive}/>
                            </li>
                            <li className={`ml-5`}><NavLink to="/about" activeClassName={`u-textColorDarker`} className={`u-fontSize16 u-phone-fontSize20 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>About</NavLink></li>
                            <li className={`ml-5`}><NavLink to="/contact" activeClassName={`u-textColorDarker`} className={`u-fontSize16 u-phone-fontSize20 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>Contact</NavLink></li>
                            <li className={`ml-5`}><NavLink to="/careers" activeClassName={`u-textColorDarker`} className={`u-fontSize16 u-phone-fontSize20 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>Career</NavLink></li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
} 

export default withRouter(Header);