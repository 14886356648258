import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const Map = (props) =>
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: 28.448689, lng: 77.035368 }} >
    {props.isMarkerShown && <Marker position={{ lat: 28.448689, lng: 77.035368 }} />}
  </GoogleMap>;

  export default withScriptjs(withGoogleMap(Map));