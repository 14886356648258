import React from 'react';

import Section from '../../components/UI/Section/Section';
import careersMapping from '../../assets/json/careers.json';
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Job = (props) => {

    let job = null;

    for (let i = 0; i < careersMapping.length; i++) {
        if (props.match.params.id === careersMapping[i].id) {
            job = careersMapping[i];
        }
    }

    return (
        <Section>
            <div className={`u-flex f-row phone-f-column`}>
                <div className={`u-flex30 u-phoneFlexAuto phone-mb-4`}>
                    <ul className="u-flex f-column phone-f-row phone-justify-content-between u-lineHeight1">
                        <li className={`pt-4 pb-4 phone-pt-2 phone-pb-2 u-textColorPrimary u-borderBottomNormal u-phone-borderBottomNone u-fontWeightMedium u-flex align-items-center`}>
                            <NavLink to="/careers" className={`u-textColorPrimary`}><span className={`mr-3`}><FontAwesomeIcon icon='arrow-left' size="1x"/></span>Other Roles</NavLink>
                        </li>
                        <li className="u-phoneShow u-borderVertical"></li>
                        <li className={`pt-4 pb-4 phone-pt-2 phone-pb-2 u-textColorDark u-borderBottomNormal u-phone-borderBottomNone u-fontWeightMedium u-flex align-items-center`}>
                            <span className={`mr-3 u-phoneHide`}><FontAwesomeIcon icon='globe' className={`u-textColorPrimary`} size="1x"/></span>{job.region}
                        </li>
                        <li className="u-phoneShow u-borderVertical"></li>
                        <li className={`pt-4 pb-4 phone-pt-2 phone-pb-2 u-textColorDark u-fontWeightMedium u-phone-borderBottomNone u-flex align-items-center`}>
                            <span className={`mr-3 u-phoneHide`}><FontAwesomeIcon icon='clock' className={`u-textColorPrimary`} size="1x"/></span>{job.type}
                        </li>
                    </ul>
                </div>
                <div className={`f-fill pl-6 pr-6 phone-pl-0 phone-pr-0`}>
                    <div className={`mb-6`}>
                        <div className={`mb-2`}>
                            <h2 className={`u-textColorDarkest u-fontSize42 u-phone-fontSize36 u-fontWeightRegular`}>{job.title}{ job.level.length > 1 ? <span className="u-fontSize22 u-phone-fontSize18 u-textColorNormal">&nbsp;({job.level})</span> : null }</h2>
                        </div>
                        <h3 className={`u-textColorNormal u-fontSize20 u-fontWeightRegular`}>{job.subTitle}</h3>
                    </div>
                    <div className={`mb-8`}>
                        <div className={`mb-2`}>
                            <p className={`u-textColorDarkest u-fontSize16`}>{job.overview}</p>
                        </div>
                        <p className={`u-textColorDarkest u-fontSize16`}>If you’re hungry, smart, persistent, and a great teammate, we want to hear from you!</p>
                    </div>
                    <div className={`mb-5`}>
                        <div className={`mb-3`}>
                            <h4 className={`u-textColorDarkest u-fontSize18 u-fontWeightMedium`}>You Will:</h4>
                        </div>
                        <ul>
                            {job.exposure.map((exposure, index) => {
                                return <li key={index} className={`u-fontSize16 u-textColorDarker mb-4 u-relative u-lineHeight15 u-flex align-items-center`}><span className={`u-size24x24 mr-3 u-borderRadius50 u-flex justify-content-center align-items-center`} style={{
                                    backgroundColor: 'rgb(102, 196, 172, .15)'
                                }}><FontAwesomeIcon icon='check' className={`u-textColorPrimary`} size='sm' /></span>{exposure.title}</li>
                            })}
                        </ul>
                    </div>
                    <div className={`mb-8`}>
                        <div className={`mb-3`}>
                            <h4 className={`u-textColorDarkest u-fontSize18 u-fontWeightMedium`}>We’re looking for someone who has:</h4>
                        </div>
                        <ul>
                            {job.qualification.map((exposure, index) => {
                                return <li key={index} className={`u-fontSize16 u-textColorDarker mb-4 u-relative u-lineHeight15 u-flex align-items-center`}><span className={`u-size24x24 mr-3 u-borderRadius50 u-flex justify-content-center align-items-center`} style={{
                                    backgroundColor: 'rgb(102, 196, 172, .15)'
                                }}><FontAwesomeIcon icon='check' className={`u-textColorPrimary`} size='sm' /></span>{exposure.title}</li>
                            })}
                        </ul>
                    </div>
                    <div className={`pt-6 pb-6 pl-8 pr-8 u-borderRadius4 u-flex align-items-center u-backgroundColorPrimaryLight phone-pl-3 phone-pr-3 phone-pt-3 phone-pb-3`}>
                        <p className={`u-fontSize20 u-phone-fontSize18 u-fontWeightRegular u-textColorDark`}>We look forward to hearing from you</p>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default Job;