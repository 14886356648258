import React from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServiceCard = (props) => {

    const { title, description, colorCode, backgroundColorCode, icon, reactUrl } = props.data;

    return (
        <Link to={reactUrl}>
            <article className={`u-boxShadow u-boxShadowHover u-flex f-column justify-content-center u-backgroundColoWhite u-borderRadius4 u-width100Percent u-height100Percent u-transition-all150Ease u-transform-translateYHover u-cursorPointer pt-7 pb-7 pl-4 pr-4 u-backgroundColorWhite`} style={{
                boxShadow: `inset 0 6px 0 ${colorCode}, 0 5px 9px rgba(0,0,0,.08)`
            }}>
                <div className={`u-textAlignCenter`}>
                    <div className={`mb-6 u-size110x110 mx-auto u-flex justify-content-center align-items-center u-borderRadius50`} style={{
                        backgroundColor: backgroundColorCode
                    }}>
                        <FontAwesomeIcon icon={icon} color={colorCode} size="3x"/>
                    </div>
                    <div className={`mb-6`}>
                        <h4 className={`u-fontWeightMedium u-fontSize24`} style={{color: colorCode}}>{title}</h4>
                    </div>
                    <p className={`u-fontSize16 u-textColorDark mb-6`}>{description}</p>
                </div>
                <div className={`u-textAlignCenter`}>
                    <span className={`u-fontWeightSemiBold u-textColorLink`}>Learn more</span>
                </div>
            </article>
        </Link>
    );
}

export default ServiceCard;