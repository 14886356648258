import React from 'react';

import Section from '../../components/UI/Section/Section';
import SectionHeading from '../../components/UI/SectionHeading/SectionHeading';
import Map from '../Map/Map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact = () => {

    return (
        <Section>
            <div className={`mb-8 u-textAlignCenter`}>
                <SectionHeading>Contact Us</SectionHeading>
            </div>
            <div className="u-flex f-row justify-content-between tabPort-f-column">
                <div className="u-width65Percent phone-mb-10 tabPort-mb-16 u-height420 u-tabPort-width100Percent">
                    <Map
                        isMarkerShown
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD4iFe6MWK26hIRic5WIwQTZfby_Y2NrWY&callback=initMap"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        />
                </div>
                <div className="u-flex30">
                    <ul className="u-flex f-column tabPort-f-row phone-f-wrap tabPort-justify-content-between">
                        <li className="mb-8">
                            <h5 className="u-fontSize16  u-fontWeightSemiBold u-textColorPrimary mb-2"><span className="mr-1"><FontAwesomeIcon icon='map-marker' className={`u-textColorPrimary`} size="1x"/></span>Location</h5>
                            <h3 className="u-fontSize20 u-fontWeightRegular u-textColorDark">Basement, H.No. 99<br/>Sector 15 Part&nbsp;-&nbsp;1<br/>Gurugram&nbsp;-&nbsp;122001</h3>
                        </li>
                        <li className="mb-8">
                            <h5 className="u-fontSize16  u-fontWeightSemiBold u-textColorPrimary mb-2"><span className="mr-1"><FontAwesomeIcon icon='clock' className={`u-textColorPrimary`} size="1x"/></span>Office Hours</h5>
                            <h3 className="u-fontSize20 u-fontWeightRegular u-textColorDark">Monday&nbsp;-&nbsp;Saturday<br/>10&nbsp;am - 6&nbsp;pm</h3>
                        </li>
                        <li>
                            <h5 className="u-fontSize16  u-fontWeightSemiBold u-textColorPrimary mb-2"><span className="mr-1"><FontAwesomeIcon icon='phone' className={`u-textColorPrimary`} size="1x"/></span>Contact</h5>
                            <h3 className="u-fontSize20 u-fontWeightRegular u-textColorDark">
                                <a href="mailto: sk9811141515@yahoo.com" target="_blank" rel="noopener noreferrer" className="u-textColorDark u-textColorDarkerHover">sk9811141515@yahoo.com</a>
                                <br/>
                                <a href="tel:0124-4035250" className="u-textColorDark u-textColorDarkerHover">0124-4035250</a>&nbsp;, <a href="tel:+91-9873803698" className="u-textColorDark u-textColorDarkerHover">9873803698</a>
                            </h3>
                        </li>
                    </ul>
                </div>
            </div>
        </Section>
    );
}

export default Contact;
