import React from 'react';

import auditAndAttestationMapping from '../../assets/json/auditAndAttestation';
import businessServicesMapping from '../../assets/json/businessServices.json';
import gstMapping from '../../assets/json/gst.json';
import incomeTaxMapping from '../../assets/json/incomeTax.json';
import companyLawMapping from '../../assets/json/companyLaw.json';

import auditAndAttestationImage from '../../assets/images/audit-attestation.jpg';
import businessServicesImage from '../../assets/images/business-services.jpg';
import gstMappingImage from '../../assets/images/gst.jpg';
import incomeTaxImage from '../../assets/images/income-tax.jpg';
import companyLawImage from '../../assets/images/company-law.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const service = (props) => {

    const { 
        type,
        title, 
        description, 
        heading, 
        icon,
        colorCode, 
        backgroundColorCode, 
    } = props;

    let mapping = null;
    let image = null;

    if (type === 'income-tax') {
        mapping = incomeTaxMapping;
        image = incomeTaxImage;
    } else if (type === 'company-law') {
        mapping = companyLawMapping;
        image = companyLawImage;
    } else if (type === 'business') {
        mapping = businessServicesMapping;
        image = businessServicesImage;
    } else if (type === 'gst') {
        mapping = gstMapping;
        image = gstMappingImage;
    } else if (type === 'audit-and-attestation') {
        mapping = auditAndAttestationMapping;
        image = auditAndAttestationImage;
    }

    return (
        <div className={`pb-20 phone-pb-10`}>
            <div className={`u-flex u-height100PercentOffset80 u-tabLand-heightAuto u-phone-height100Percent u-relative`}>
                <div className={`u-flex phone-f-column align-items-center pt-10 pb-12`}>
                    <div className={`u-width50Percent u-phone-width100Percent phone-pb-8`}>
                        <div className={`mb-3 u-flex align-items-center`}>
                            <div className={`u-size48x48 u-flex justify-content-center align-items-center u-borderRadius50 mr-3`} style={{ backgroundColor: backgroundColorCode }}>
                                <FontAwesomeIcon icon={icon} color={colorCode} size="lg"/>
                            </div>
                            <h5 className={`u-fontSize24 u-fontWeightRegular`} style={{ color: colorCode }}>{title}</h5>
                        </div>
                        <div>
                            <div className={`mb-6 u-relative`}>
                                <h2 className={`u-textColorDarker u-fontSize42 u-phone-fontSize36 u-fontWeightMedium u-lineHeight12`}>{heading}</h2>    
                            </div>
                            <p className={`u-textColorDark u-fontSize18 u-fontWeightRegular`}>{description}</p>
                        </div>
                    </div>
                    <div className={`u-width50Percent u-phone-width100Percent phone-pt-4 phone-pb-4`}>
                        <div className={`u-width85Percent u-phone-width100Percent ml-auto`}>
                            <img src={image} alt={title} className={`u-width100Percent`}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`u-flex f-wrap justify-content-between phone-f-column`}>
                {
                    mapping.map((service, index) => {
                        return <section className={`pt-10 pb-10 phone-pt-6 phone-pb-6 section-service`} key={index}>
                            <div className={`u-flex f-column`}>
                                <div className={`mb-3 u-flex align-items-center`}>
                                    <div className={`u-size48x48 u-flex justify-content-center align-items-center u-borderRadius50 mr-3`} style={{ backgroundColor: backgroundColorCode }}>
                                        <FontAwesomeIcon icon={service.icon} color={colorCode} size="lg"/>
                                    </div>
                                    <h5 className={`u-fontSize18  u-fontWeightRegular`} style={{ color: colorCode }}>{service.title}</h5>
                                </div>
                                <div className={`mb-4`}>
                                    <h3 style={{ color: '#32325d' }} className={`u-fontSize24 u-fontWeightRegular`}>{service.heading}</h3>
                                </div>
                                <p className={`u-fontSize16 u-textColorDarker`}>{service.description}</p>
                                {
                                    service.subServices ? <ul className={`mt-4`}>
                                        {
                                            service.subServices.map((subService, index) => {
                                                return <li key={index.toString()} className={`mb-2 u-fontSize16 u-textColorDarker u-flex align-items-center`}><span className={`u-size24x24 mr-3 u-borderRadius50 u-flex justify-content-center align-items-center`} style={{
                                                    backgroundColor: backgroundColorCode
                                                }}><FontAwesomeIcon icon='check' color={colorCode} size='sm' /></span>{subService.title}</li>
                                            })
                                        }
                                    </ul> : null
                                }
                            </div>
                        </section>
                    })
                }
            </div>
        </div>
    );
}

export default service;