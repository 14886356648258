import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Home from './containers/Home/Home';
import About from './containers/About/About';
import Services from './containers/Services/Services';
import Careers from './containers/Careers/Careers';
import Job from './components/Job/Job';
import Contact from './components/Contact/Contact';
import Container from './components/UI/Container/Container';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faBriefcase, faFileInvoice, faFileContract, faGavel, faSearch, faHandshake, faCertificate, faExchangeAlt, faPaperPlane, faFistRaised, faWrench, faHistory, faHandsHelping, faFileAlt, faHourglassHalf, faSignature, faPen, faChartPie, faSearchDollar, faRupeeSign, faCompressArrowsAlt, faGripLinesVertical, faWallet, faExclamation, faCoins, faUndo, faUsers, faFileSignature, faCheck, faClock, faGlobe, faArrowLeft, faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons';

library.add(faAngleRight, faBriefcase, faFileContract, faFileInvoice, faGavel, faSearch, faHandshake, faCertificate, faExchangeAlt, faPaperPlane, faFistRaised, faWrench, faHistory, faHandsHelping, faFileAlt, faHourglassHalf, faSignature, faPen, faChartPie, faSearchDollar, faRupeeSign, faCompressArrowsAlt, faGripLinesVertical, faWallet, faExclamation, faCoins, faUndo, faUsers, faFileSignature, faCheck, faClock, faGlobe, faArrowLeft, faPhone, faMapMarker);



class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Header/>
        <div className="pt-16">
          <Switch>
            <Route exact path='/' component={Home}/>
            <Container>
              <Route path='/about' component={About}/>
              <Route path='/service' component={Services}/>
              <Route path='/careers' component={Careers}/>
              <Route path='/contact' component={Contact}/>
              <Route path='/job/:id' component={Job}/>
            </Container>
          </Switch>
        </div>
        <Footer/>
      </React.Fragment>
    );
  }
}

export default App;
