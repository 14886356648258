import React from 'react';

const Hamburger = (props) => (
    <button className={`menu-toggle ${props.isCollapsed ? 'collapsed' : null}`} onClick={props.clicked}>
        <div className={`menu-bar menu-bar-${props.bgColor}`}></div>
        <div className={`menu-bar menu-bar-${props.bgColor}`}></div>
        <div className={`menu-bar menu-bar-${props.bgColor}`}></div>
    </button>
);

export default Hamburger;