import React from 'react';
import { NavLink } from "react-router-dom";

import Container from '../UI/Container/Container';

const Footer = () => (
    <footer className={`u-borderTopFooter u-zIndex500 u-relative u-backgroundColorWhite`}>
        <Container>
            <div className={`u-flex justify-content-between pt-12 pb-12 phone-f-column`}>
                <div className="phone-mb-4">
                    <div className={`mb-3 phone-mb-0`}>
                        <NavLink to="/" className={`u-fontSize24 u-textColorDarker u-fontWeightSemiBold`}>Sandeep Kumar &amp; Associates</NavLink>
                    </div>
                    <p className={`u-fontSize16 u-fontWeightRegular u-textColorDark`}>&copy; Copyright 2019 Sandeep Kumar &amp; Associates <br className="u-phoneHide"/> All rights reserved.</p>
                </div>
                <div className={`u-flex phone-f-wrap phone-f-column`}>
                    <div className={`u-textAlignLeft phone-mb-4`}>
                        <div className={`mb-3 phone-mb-2`}>
                            <h5 className={`u-fontSize16 u-fontWeightSemiBold u-textColorDark u-textTransformUppercase`}>Company</h5>
                        </div>
                        <ul>
                            <li><NavLink to="/" className={`u-fontSize16 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>Home</NavLink></li>
                            <li><NavLink to="/about" className={`u-fontSize16 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>About</NavLink></li>
                            <li><NavLink to="/careers" className={`u-fontSize16 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>Career</NavLink></li>
                        </ul>
                    </div>
                    <div className={`ml-12 u-textAlignLeft phone-ml-0 phone-mb-4`}>
                        <div className={`mb-3 phone-mb-2`}>
                            <h5 className={`u-fontSize16 u-fontWeightSemiBold u-textColorDark u-textTransformUppercase`}>Services</h5>
                        </div>
                        <ul>
                            <li><NavLink to="/service/income-tax" className={`u-fontSize16 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>Income Tax</NavLink></li>
                            <li><NavLink to="/service/company-law" className={`u-fontSize16 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>Company Law</NavLink></li>
                            <li><NavLink to="/service/business" className={`u-fontSize16 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>Business</NavLink></li>
                            <li><NavLink to="/service/gst" className={`u-fontSize16 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>GST</NavLink></li>
                            <li><NavLink to="/service/audit-attestation" className={`u-fontSize16 u-textColorNormal u-textColorDarkerHover u-transition--transform300Spring`}>Audit &amp; Attestation</NavLink></li>
                        </ul>
                    </div>
                    <div className={`ml-12 u-textAlignLeft phone-ml-0 phone-mb-4`}>
                        <div className={`mb-3 phone-mb-2`}>
                            <h5 className={`u-fontSize16 u-fontWeightSemiBold u-textColorDark u-textTransformUppercase`}>Contact Us</h5>
                        </div>
                        <ul>
                            <li className={`mb-1 u-textColorDark`}>
                                Basement, H.No. 99<br/>
                                Sector 15 Part-1, Gurgaon-122001
                            </li>
                            <li className={`mb-1`}>
                                <a href="mailto: sk9811141515@yahoo.com" target="_blank" rel="noopener noreferrer" className="u-textColorNormal u-textColorDarkerHover">sk9811141515@yahoo.com</a>
                            </li>
                            <li>
                                <a href="tel:0124-4035250" className="u-textColorNormal u-textColorDarkerHover">0124-4035250</a>&nbsp;, <a href="tel:+91-9873803698" className="u-textColorNormal u-textColorDarkerHover">9873803698</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Container>
    </footer>
);

export default Footer;