import React from 'react';

const PersonCard = (props) => {

    const { name, position, description, image } = props;

    return (
        <div className="u-width50Percent u-phone-width100Percent pl-4 pr-4 phone-pl-0 phone-pr-0">
            <div className="u-flex f-column justify-content-center align-items-center">
                <div className="mb-4 u-borderRadius50 u-size160x240">
                    <img src={`img/${image}.jpg`} alt='Sandeep Kumar' className="u-width100Percent u-height100Percent u-inlineBlock"/>
                </div>
                <h3 className="u-fontSize18 u-textColorDarker u-fontWeightSemiBold u-lineHeight1">{name}</h3>
                <h4 className="u-fontSize16 u-textColorNormal u-fontWeightMedium mb-4">{position}</h4>
                <h5 className="u-fontSize16 u-textColorNormal u-fontWeightRegular u-phone-textAlignCenter">{description}</h5>
            </div>
        </div>
    )
}

export default PersonCard;